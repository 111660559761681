import React, { useState } from "react";
import { initializeApp } from "firebase/app";
import { getDatabase, ref, onValue, push, set } from "firebase/database";
import {signOut} from "firebase/auth";
import { useNavigate } from "react-router-dom";


const firebaseConfig = {
  apiKey: "AIzaSyDVPYaw7m9-ZOx5A6yCHD9dMW0VJncCTIM",
  authDomain: "vertigosloth.firebaseapp.com",
  databaseURL: "https://vertigosloth-default-rtdb.firebaseio.com",
  projectId: "vertigosloth",
  storageBucket: "vertigosloth.appspot.com",
  messagingSenderId: "812761511900",
  appId: "1:812761511900:web:7b3c8c5771a771fca48119",
  measurementId: "G-ZX01MFBS8L",
};

const app = initializeApp(firebaseConfig);

const db = getDatabase(app);
const gamesList = ref(db, "GameList");
const suggList = ref(db, "Suggestions");

const Crud = ({user}) => {
  const [games, setGames] = useState([]);
  const [suggest, setSuggest] = useState([]);

  React.useEffect(() => {
    document.title = "Dashboard";
    load();
  }, []);

  const navigate = useNavigate();

  function load() {
    let holder = [];
    let holder2 = [];
    onValue(suggList, (snapshot) => {
      snapshot.forEach((game) => {
        const wrapper = game.val();
        wrapper.id = game.ref._path.pieces_[1];
        holder2.push(wrapper);
      });
      setSuggest(holder2);
    })
    onValue(gamesList, (snapshot) => {
      snapshot.forEach((game) => {
         const wrapper = game.val();
         wrapper.id = game.ref._path.pieces_[1];
        holder.push(wrapper);
      });
      setGames(holder);
    });
  }

  function add() {
    const title = document.getElementById("title");
    const img = document.getElementById("img");
    const year = document.getElementById("year");
    const played = document.getElementById("played");
    const banned = document.getElementById("banned")
    var addGameRef;
    if(year.value === ''){
      addGameRef = push(suggList);
    }
    else{
      addGameRef = push(gamesList);
    }
    //add a way to add to database
    set(addGameRef, {
      title: title.value,
      img: img.value,
      year: year.value,
      played: played.checked,
      banned: banned.checked,
    });
    title.value = "";
    img.value = "";
    year.value = "";
    played.checked = false;
    banned.checked = false;
  }

  const edit = (title) => {
    const gameInfo = document.getElementById(title);
    const gameChild = gameInfo.childNodes;
    document.getElementById("exampleModalLabel").innerHTML = title;
    document.getElementById("url").value =gameChild[1].innerHTML;
    document.getElementById("yr").value = gameChild[4].innerHTML;

   if(gameChild[2].innerHTML === "✔️"){
      document.getElementById("playToggle").checked = true;
   }
   else{
      document.getElementById("playToggle").checked = false;
   }
    if(gameChild[3].innerHTML === "✔️"){
      document.getElementById("banToggle").checked = true;
    }
    else{
      document.getElementById("banToggle").checked = false;
    }
  };

  function save(){
   const title = document.getElementById("exampleModalLabel").innerHTML;
   const url = document.getElementById("url").value;
   const year = document.getElementById("yr").value;
   const played = document.getElementById("playToggle").checked;
   const ban = document.getElementById("banToggle").checked;
   if(year===''){
    for(let game of suggest){
      if(game.title === title){
        const gameRef = ref(db, 'Suggestions/'+game.id);
        set(gameRef, {
          title: title,
          img: url,
          year: year,
          played: played,
          banned: ban,
        });
        break;
      }
     }
   }
   else{
    for(let game of games){
      if(game.title === title){
         const gameRef = ref(db, 'GameList/'+game.id);
         set(gameRef, {
            title: title,
            img: url,
            year: year,
            played: played,
            banned: ban
         });
         break;
      }
    }
   }
   load();
   document.getElementById("x").click();
  }

  function byebye(){
    const title = document.getElementById("exampleModalLabel").innerHTML;
    const year = document.getElementById("yr").value;
    if(year === ''){
      for(let game of suggest){
        if(game.title === title){
          const gameRef = ref(db, 'Suggestions/'+game.id);
          set(gameRef, null);
          break;
        }
      }
    }
    else{
      for(let game of games){
        if(game.title === title){
          const gameRef = ref(db, 'GameList/'+game.id);
           set(gameRef, null);
           break;
        }
     }
    }
   load();
   document.getElementById("x").click();
  }

  function toBack(){
    const title = document.getElementById("exampleModalLabel").innerHTML;
    const url = document.getElementById("url").value;
    const year = document.getElementById("yr").value;
    const check1 = document.getElementById("playToggle").checked;
    const check2 = document.getElementById("banToggle").checked;
    if(year === ''){
      for(let game of suggest){
        if(game.title === title){
          const gameRef = ref(db, 'Suggestions/'+game.id);
          set(gameRef, null);
          break;
        }
      }
    }
    else{
      for(let game of games){
        if(game.title === title){
          const gameRef = ref(db, 'GameList/'+game.id);
           set(gameRef, null);
           break;
        }
     }
    }
   const addGameRef = push(gamesList);
    //add a way to add to database
    set(addGameRef, {
      title: title,
      img: url,
      year: year,
      played: check1,
      banned: check2,
    });
    load();
    document.getElementById("x").click();
  }

  const logout = () => {
    signOut(user[0]).then(() => {
      //console.log("Should have worked");
      user[1](false);
      navigate("/gameList");
    }).catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode + ": " + errorMessage);
    })
  }

  return (
    <div className="">
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <a className="navbar-brand mb-0 h1" href="/">
          vertigosloth
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbar"
          aria-controls="navbar"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbar">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <a className="nav-link" href="/gamelist">
                Game List
              </a>
            </li>
            <li>
              <button className="btn btn-secondary form-input" onClick={logout}>
                Logout
              </button>
            </li>
          </ul>
        </div>
      </nav>
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog editBox" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Modal title
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true" id="x">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-3">Image URL:</div>
                <input type="text" id="url" className="col imgUrl"/>
              </div>
              <div className="row">
                <div className="col-2">Year</div>
                <input type="text" id="yr" className="col imgUrl"/>
              </div>
              <div className="row">
                <div className="col-2">
                  <label>Played</label>
                </div>
                <input type="checkbox" id="playToggle"/>
              </div>
              <div className="row">
                <div className="col-2">
                  <label>Banned</label>
                </div>
                <input type="checkbox" id="banToggle"/>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-warning" onClick={() => toBack()}>
                Send to Back
              </button>
              <button type="button" className="btn btn-danger" onClick={() => byebye()}>
                Delete
              </button>
              <button type="button" className="btn btn-primary" onClick={() => save()}>
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="container my-1">
        <div className="mx-2 my-3 border rounded shadow">
          <ul className="nav nav-tabs">
            <li className="nav-item" onClick={() => load()}>
              <a className="nav-link active" href="#all" data-toggle="tab">
                All
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#add" data-toggle="tab">
                Add
              </a>
            </li>
            <li className="nav-item" onClick={() => load()}>
               <a className="nav-link" href="#sugg" data-toggle="tab">
                  Suggestions
               </a>
            </li>
          </ul>
          <div className="tab-content">
            <div className="tab-pane fade active show" id="all">
              <div className="table-container">
                <span className="bold-text">Title</span>
                <span className="bold-text">Image</span>
                <span className="bold-text">Played</span>
                <span className="bold-text">Banned</span>
                <span className="bold-text">Year</span>
              </div>
              {games.map((game)=> {
                  return(
                    <div id={game.title} onClick={() => edit(game.title)} data-toggle="modal" data-target="#exampleModal" className="darken table-container">
                      <div className="titleClip" title={game.title}>{game.title}</div>
                      <div className="imgUrl">{game.img}</div>
                      {game.played ? <span>✔️</span> : <span>❌</span>}
                      {game.banned ? <span>✔️</span> : <span>❌</span>}
                      <span>{game.year}</span>
                    </div>
                  )
                })}
            </div>
            <div className="tab-pane fade" id="add">
              <div className="squeeze">
                <label htmlFor="title" className="form-label">
                  Title
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="title"
                  required
                />
                <div className="invalid-feedback">Need a title</div>
              </div>
              <div className="squeeze">
                <label htmlFor="img" className="form-label">
                  Image URL
                </label>
                <input type="text" className="form-control" id="img" required />
              </div>
              <div className="squeeze">
                <label htmlFor="year" className="form-label">
                  Year
                </label>
                <input type="text" className="form-control" id="year"/>
              </div>

              <div className="squeeze">
                <input type="checkbox" className="" id="played" />
                <label htmlFor="played" className="form-label">
                  Played
                </label>
              </div>
              <div className="squeeze">
                <input type="checkbox" className="" id="banned" />
                <label htmlFor="banned" className="form-label">
                  Banned
                </label>
              </div>
              <div className="squeeze">
                <button
                  type="submit"
                  className="btn btn-primary btn-block"
                  onClick={() => add()}
                >
                  Submit
                </button>
              </div>
              <div className="bumper" />
            </div>
            <div className="tab-pane fade" id="sugg">
              <div className="table-container">
                <span className="bold-text">Title</span>
                <span className="bold-text">Image</span>
                <span className="bold-text">Played</span>
                <span className="bold-text">Banned</span>
                <span className="bold-text">Year</span>
              </div>
              {suggest.map((game)=> {
                  return(
                    <div id={game.title} onClick={() => edit(game.title)} data-toggle="modal" data-target="#exampleModal" className="darken table-container">
                      <div className="titleClip" title={game.title}>{game.title}</div>
                      <div className="imgUrl">{game.img}</div>
                      {game.played ? <span>✔️</span> : <span>❌</span>}
                      {game.banned ? <span>✔️</span> : <span>❌</span>}
                      <span>{game.year}</span>
                    </div>
                  )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Crud;
