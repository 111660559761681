import React from 'react'
import './App.css';
import {Route, Routes} from 'react-router-dom';
import Landing from './components/Landing';
import List from './components/List.js';
import Nope from './components/Nope.js';
import Login from './components/Login.js';
// import NewHome from './components/NewHome.js';
// import Info from './components/Info.js';
// import Projects from './components/Projects.js';
// import Stickers from './components/Stickers.js';
//import WordleHelper from './components/WordleHelper.js';


function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Landing />}/>
        <Route path="/gamelist" element={<List />}/>
        <Route path="login" element={<Login />}/>
        {/* <Route path="/test" element={<NewHome />}/>
        <Route path='/info' element={<Info />}/>
        <Route path='/projects' element={<Projects />}/>
        <Route path="/stickers" element={<Stickers />}/>
        <Route path="/wordlehelper" element={<WordleHelper />}/> */}
        <Route path="*" element={<Nope />}/>
      </Routes>
    </div>
  );
}

export default App;
