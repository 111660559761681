import React, { useEffect, useState } from "react";
import Crud from './Crud.js';
import {getAuth, signInWithEmailAndPassword} from "firebase/auth";

 const auth = getAuth();

const Login = () => {

   useEffect(() => {
      document.title = "Login";
   })

   const [warning, setWarning] = useState(false);
   const [logged, setLogged] = useState(false);

   const login = () => {
      let username = document.getElementById("me").value;
      let password = document.getElementById("you").value;

      signInWithEmailAndPassword(auth, username, password)
      .then((userCredential)=> {
         setWarning(false);
         setLogged(true);
      })
      .catch((error) => {
         const errorCode = error.code;
         const errorMessage = error.message;
         console.log(errorCode +": " + errorMessage);
         setWarning(true);
         setLogged(false);
      })
   }

   // const signup = () => {
   //    let username = document.getElementById("me").value;
   //    let password = document.getElementById("you").value;
      
   //    createUserWithEmailAndPassword(auth, username, password)
   //      .then((userCredential) => {
   //        // Signed in
   //        const user = userCredential.user;
          
   //      })
   //      .catch((error) => {
   //        const errorCode = error.code;
   //        const errorMessage = error.message;
   //        console.log(errorCode +": " + errorMessage);
   //      });
   // }

   const passed = (update) => {
      setLogged(update);
   }

   return(
      logged ?
      <Crud user={[auth, passed.bind(this)]} />
      :
      <div>
         <main className="form-signin text-center w-100 m-auto">
            <div>
               <h1 className="h3 mb-3 fw-normal">Sign In</h1>
               <div className="form-label-group mb-2">
                  <input className="form-control" id="me" placeholder="Not Password"/>
               </div>
               <div className="form-floating mb-2">
                  <input type="password" className="form-control" id="you" placeholder="Password"/>
               </div>
               <button className="w-100 btn btn-lg btn-primary" onClick={login}>Login</button>
            </div>
            {warning && <div style={{
            backgroundColor: "red"
         }}>Wrong</div>}
         </main>
      </div>
   );
};

export default Login;