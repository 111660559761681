import React from "react";
import { initializeApp } from "firebase/app";
import {
  getDatabase,
  ref,
  onValue,
  query,
  orderByChild,
} from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyDVPYaw7m9-ZOx5A6yCHD9dMW0VJncCTIM",
  authDomain: "vertigosloth.firebaseapp.com",
  databaseURL: "https://vertigosloth-default-rtdb.firebaseio.com",
  projectId: "vertigosloth",
  storageBucket: "vertigosloth.appspot.com",
  messagingSenderId: "812761511900",
  appId: "1:812761511900:web:7b3c8c5771a771fca48119",
  measurementId: "G-ZX01MFBS8L",
};

const app = initializeApp(firebaseConfig);

const db = getDatabase(app);
const gameList = query(ref(db, "GameList"), orderByChild("played"));
const suggList = ref(db, "Suggestions");

const List = () => {
  const [search, setSearch] = React.useState("");
  const [mainList, setMainList] = React.useState([]);
  const [order, setOrder] = React.useState(false);
  const [filt, setFilt] = React.useState("");
  const [group, setGroup] = React.useState("24");

  const startUp = () => {
    let holder = [];
    onValue(suggList, (snapshot) => {
      snapshot.forEach((game) => {
        const wrapper = game.val();
        wrapper.year = 's';
        holder.push(wrapper);
      });
    })
    onValue(gameList, (snapshot) => {
      snapshot.forEach((game) => {
        holder.push(game.val());
      });
      setMainList(holder);
    });
  };

  React.useEffect(() => {
    startUp();
    document.title = "Game List";
    document.getElementById("default").checked = true;
    document.getElementById("24").checked = true;
  }, []);


  const filteredList = (order, mainList, search, filt, grp) =>{
    var temp = mainList.filter(game => {
      return game.title.toUpperCase().includes(search.toUpperCase()) && game.year===grp;
    });
    if(filt === "played"){
      temp = temp.filter(game => {
        return game.played === true;
      })
    }
    else if(filt === "banned"){
      temp = temp.filter(game => {
        return game.banned === true;
      })
    }
    if(order === true){
      temp = reverse(temp);
    }
    return temp;
  }

  const filteredItems = {list: filteredList(order, mainList, search, filt, group)};


  function sortDefault() {
    setSearch("");
    setFilt("");
  }

  const sortPlayed = () => {
    setSearch("");
    setFilt("played");
  };

  const sortBan = () => {
    setSearch("");
    setFilt("banned")
  };

  function reverse(list){
    var holder;
    for(var i=0; i<list.length/2; i++){
      holder = list[i];
      list[i] = list[list.length-1-i];
      list[list.length-i-1] = holder;
    }
    return list;
  }

  function orderStream(){
    setSearch("");
    setOrder(!order);
  }

  const listOnes = (year) => {
    setSearch("");
    setGroup(year);
  }

  const chooser = (letter) => {
    if(letter === "a"){
      sortDefault();
    }
    else if(letter === "p"){
      sortPlayed();
    }
    else{
      sortBan();
    }
  }

  return (
    <div className="proper">
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <a className="navbar-brand mb-0 h1" href="/">
          vertigosloth
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbar"
          aria-controls="navbar"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbar">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <a className="nav-link" href="https://www.twitch.tv/vertigosloth">
                Twitch
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/gamelist">
                Game List
              </a>
            </li>
          </ul>
        </div>
      </nav>
      <div className="filters">
        <h5 className="text-center">Game List</h5>
        <div className="gameCount">
          Games: {filteredItems.list.length}
        </div>
        <div className="checkHolder">
          <input type="radio" name="listOption" id="22" style={{width: "23px", height: "23px", float: "left", marginLeft: "10px"}} onClick={()=> listOnes('22')}/>
          <label style={{marginLeft: "45px"}}>2022</label>
          <input type="radio" name="listOption" id="23" style={{width: "23px", height: "23px", float: "left", marginLeft: "10px"}} onClick={()=> listOnes('23')}/>
          <label style={{marginLeft: "45px"}}>2023</label>
          <input type="radio" name="listOption" id="24" style={{width: "23px", height: "23px", float: "left", marginLeft: "10px"}} onClick={()=> listOnes('24')}/>
          <label style={{marginLeft: "45px"}}>2024</label>
          <input type="radio" name="listOption" id="s" style={{width: "23px", height: "23px", float: "left", marginLeft: "10px"}} onClick={()=> listOnes('s')}/>
          <label style={{marginLeft: "45px"}}>Suggestions</label>
        </div>
        <h5 className="text-center">Filter Games By</h5>
        <div className="bumper" />
        <div className="checkHolder">
          <input type="radio"  name="filterOption"  id="default"  style={{  width: "23px",  height: "23px",  float: "left",  marginLeft: "10px"}} onClick={() => sortDefault()}/>
          <label style={{ marginLeft: "45px" }}>Added</label>
          <input
            type="radio"
            name="filterOption"
            id="checks"
            style={{
              width: "23px",
              height: "23px",
              float: "left",
              marginLeft: "10px",
            }}
            onClick={() => sortPlayed()}
          />
          <label style={{ marginLeft: "45px" }}>Played</label>
          <input
            type="radio"
            name="filterOption"
            id="nopes"
            style={{
              width: "23px",
              height: "23px",
              float: "left",
              marginLeft: "10px",
            }}
            onClick={() => sortBan()}
          />
          <label style={{ marginLeft: "45px" }}>Banned</label>
        </div>
        <h5 className="text-center">Order By</h5>
        <div className="bumper"/>
        <div className="checkHolder">
          {/* <input type="radio" name="orderOption" id="oDefault" style={{width: "23px", height: "23px", float: "left", marginLeft: "10px"}} onClick={() => orderDefault()}/>
          <label style={{ marginLeft: "45px" }}>Default</label> */}
          <input type="checkbox"    id="oDefault"  style={{  width: "23px",  height: "23px",  float: "left",  marginLeft: "10px"}} onClick={() => orderStream()}/>
          <label style={{ marginLeft: "45px" }}>Stream Date</label>
        </div>
      </div>
      <div className="container">
        <div className="searchbar">
          <input
            type="text"
            className="lies"
            value={search}
            onChange={e=> setSearch(e.target.value)}
            placeholder="Search"
          />
          <div className="searchFilter">
            <div className="filterRow">
              <label>Year</label>
              <select onChange={(e)=>listOnes(e.target.value)} defaultValue={"24"}>
                <option value="22">2022</option>
                <option value="23">2023</option>
                <option  value="24">2024</option>
                <option value="s">Suggestion</option>
              </select>
            </div>
            <div className="filterRow">
              <label>Type</label>
              <select defaultValue={"a"} onChange={(e) => chooser(e.target.value)}>
                <option value="a" onClick={() => sortDefault()}>Added</option>
                <option value="p" onClick={() => sortPlayed()}>Played</option>
                <option value="b" onClick={() => sortBan()}>Banned</option>
              </select>
            </div>
            <div className="filterRow">
              <label>Stream Date</label>
              <input type="checkbox" style={{  width: "23px",  height: "23px"}} onClick={() => orderStream()}/>
            </div>
          </div>
        </div>
        <div className="main-content" id="main-content">
          {filteredItems.list.map((game) => {
            return (
              <div className="test-card-wrapper" key={game.title}>
                <div className="test-card-body">
                  <div className="test-card-top">
                    <div className="test-card-image-wrapper">
                      <img
                        className="card-image"
                        src={game.img}
                        alt={game.title}
                      />
                    </div>
                  </div>
                  <div className="test-card-bottom">
                    <div key={game.title} className="test-card-title" title={game.title}>
                      {game.title}
                    </div>
                    <div className="test-card-price">
                      <div className="">
                        {game.played && <span>Played: ✔️</span>}
                        {!game.played && <span>Played: ❌</span>}
                      </div>
                    </div>
                    <div hidden className="year">
                      {game.year}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          {filteredItems.list.length === 0 && (
            <label>Suggest that game in stream chat!</label>
          )}
        </div>
      </div>
    </div>
  );
};

export default List;
