import React, { useEffect } from 'react';
import header from '../images/cowgurl.jpg';
import wheader from '../images/wordle.png';
import gheader from '../images/fall.jpg';

const Landing = () => {

   useEffect(() => {
      document.title = "Vertigosloth Homepage";
   });

   return(
      // <div className="row justify-content-center g-4 mt-5">
      //    <div className="col-3" onClick={goWords}>
      //       <div className="btn btn-dark">
      //       Wordle Helper
      //       </div>
      //    </div>
      //    <div className='col-3' onClick={goGame}>
      //       <div className="btn btn-success">
      //          Villager Duel
      //       </div>
      //    </div>
      // </div>
      <div className='landing'>
         <nav className='navbar navbar-expand-lg navbar-light bg-light fixed-top'>
            <a className='navbar-brand mb-0 h1' href='/'>vertigosloth</a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar" aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation">
               <span className="navbar-toggler-icon"></span>
            </button>
            <div className='collapse navbar-collapse' id='navbar'>
               <ul className='navbar-nav ml-auto'>
                     <li className='nav-item'>
                        <a className='nav-link' href='https://www.twitch.tv/vertigosloth'>Twitch</a>
                     </li>
                     <li className='nav-item'>
                        <a className='nav-link' href='/gamelist'>Game List</a>
                     </li>
               </ul>
            </div>
         </nav>
         <div id="first">
            <div className='banner' style={{
               backgroundImage: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${header})`,
               backgroundRepeat: 'no-repeat',
               backgroundPosition: 'center',
               backgroundSize: 'cover'
            }}>
               <h2>Home of all things vertigosloth makes</h2>
            </div>
         </div>
         <div  id="wordle">
            <a href="https://wordlehelper.tk" className='please'>
            <div className='wordBanner' style={{
               backgroundImage: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${wheader})`,
               backgroundRepeat: 'no-repeat',
               backgroundPosition: 'center',
               backgroundSize: 'cover'
            }}>
               <h2>Wordle Helper</h2>
            </div>
            </a>
         </div>
         <div  id="villager">
            <a href="https://www.twitch.tv/videos/1566998400" className='please'>
            <div className='villagerBanner'>
               Villager Duel
            </div>
            </a>
         </div>
         <div  id="games">
            <a href="/gamelist" className='please'>
            <div className='gamesBanner' style={{
               backgroundImage: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${gheader})`,
               backgroundRepeat: 'no-repeat',
               backgroundPosition: 'center',
               backgroundSize: 'cover'
            }}>
               <p>
               <h2>Games List</h2>
               The ones we play for the stream
               </p>
            </div>
            </a>
         </div>
      </div>
   );
}

export default Landing;